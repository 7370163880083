.navigation__wrapper{
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    top:0;
    padding:20px;
    text-align:right;
    z-index: 21;
    pointer-events: none;
}
.navigation__list{
    padding: 15px 0px 10px 20px;
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: flex-end;
}
.navigation__title{
    font-family: 'Panchang-Extrabold', sans-serif;
    color: #FFFFFF;
    font-size:18px;
    text-transform: uppercase;
}
.navigation__item{
    position: relative;
    display: flex;
    color: #FFFFFF;
    align-content: flex-end;
    font-size: 15px;
    opacity: 0.8;
    font-family: 'Panchang-Light', sans-serif;
    cursor: pointer;
    transition: opacity 0.2s ease-out;
    pointer-events:all;
}

.active.navigation__item{
    opacity: 1;
}
.navigation__item-span{
    position: relative;
    font-family: 'Panchang-Medium', sans-serif;
    /* pointer-events:none */
    /* padding: ; */
}
.navigation__item-span a {
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    /* transition: left 0.2s ease-out, right 0.2s ease-out; */
}
.contacts__span a{
    pointer-events:none
}
.span__hidden{
    /* display: none!important; */
}
.modal-open .navigation__item-span a, .modal-open .navigation__item{
    pointer-events: none;
}
.navigation__item-span a ~ span{
    padding: 10px;
}
@media screen and (max-width:1024px){
    .navigation__list{
        padding: 10px 0;
        column-gap:0px
    }
    .navigation__item-span{
        position: static !important ;
        left: 0% !important;
        top: 0% !important;
        width: auto !important;
        height: auto !important;
    }
    .navigation__item-span a{
        position: static !important ;
        display: block;padding: 5px 10px;
        transform: none;
    }
    
}
@media screen and (max-width:600px){
    .navigation__item{
        font-size: 12px
    }
}
@media screen and (max-width:340px){
    .navigation__item{
        font-size: 11px
    }
}