.contacts {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* justify-items: center; */
  /* align-items: center; */
  transition: background-color 0.2s ease-out;
}
.contacts.active {
  background-color: rgba(0, 0, 0, 0.65);
}
.contacts__modal-top h2 {
  text-align: center;
}
.contacts__modal {
  position: relative;
  color: #ffffff;
  /* display:flex; */
  /* flex-direction: column; */
  /* border: 3px solid white; */
  /* background-color:rgba(255,255,255,0.1); */
  row-gap: 15px;
  /* text-align: center; */
}
.contacts__modal::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 102%;
  /* background-color:red; */
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% - 3px), calc(-50% - 3px));
  z-index: -1;
  border: 1px solid #fff;
}

.contacts__modal::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 102%;
  /* background-color:blue;
     */
  border: 1px solid #fff;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% + 3px), calc(-50% + 3px));
  z-index: -1;
}

.contacts__modal h2,
.contacts__modal h3,
.contacts__modal h4,
.contacts__modal h5 {
  font-family: "Panchang-Extrabold", sans-serif;
  font-size: 18px;
  word-spacing: 10px;
  margin-bottom: 15px;
}
.contacts__modal h2 {
  font-family: "Panchang-Extrabold", sans-serif;
  font-size: 44px;
}
.contacts__modal p:nth-child(1) {
  /* padding-top: 30px; */
}
.contacts__modal p {
  padding-bottom: 15px;
  font-family: "EB Garamond", serif;
  font-size: 18px;
  text-align: justify;
}
.contacts__modal a,
.contacts__modal button {
  cursor: pointer;
}
.contacts__modal-wrapper {
  /* display:none; */
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  max-height: calc(100% - 240px);
  overflow-y: auto;
  box-sizing: border-box;
}
.contacts__button-wrap {
  position: absolute;
  width: 100%;
  bottom: 20px;
  right: 20px;
  left: 0px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 40px;
  row-gap: 10px;
  flex-wrap: wrap;
  pointer-events: none;
}

.contacts__legal {
  flex: 1;
}
.contacts__legal_p {
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  margin-right: auto;
  text-align: justify;
  font-size: 14px;
  pointer-events: all;
  font-family: "EB Garamond", serif;
}

@media screen and (max-width: 768px) {
  .contacts__legal {
    order: 99999;
    min-width: 100%;
  }
  .contacts__legal_p {
    /* text-align: right; */
    margin-right: 0px;
    max-width: 100%;
    margin-left: auto;
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .contacts__legal_p {
    text-align: justify;
  }
}
.contacts__button {
  position: relative;
  cursor: pointer;
  color: #ffffff;
  font-family: "Panchang-Medium", sans-serif;
  text-transform: capitalize;
  list-style: none;
  /* padding: 5px 10px; */
  pointer-events: all;
}
.contacts__modal {
  opacity: 0;
  position: absolute;
  /* margin:0 auto; */
  /* width: 100%;
    max-width:1024px;
    max-height: calc(100% - 240px); */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  width: 100%;
  max-width: 1024px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* overflow-y: auto; */
  padding: 0px 0px;
  z-index: -2;
  box-sizing: border-box;
  transition: opacity 0.2s linear;
  pointer-events: none;
}
.contacts__modal-inner {
  position: relative;
  /* width: 100%; */
  /* padding:0px 0px; */
  padding: 75px 75px;
  max-width: 1024px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}
.contacts__modal-inner:before {
  content: "";
  position: fixed;
  height: 75px;
  width: calc(100% - 8px);
  display: block;
  /* left: 0; */
  top: 0;
  left: 4px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.contacts__modal-inner:after {
  content: "";
  position: fixed;
  height: 75px;
  width: calc(100% - 8px);
  display: block;
  bottom: 0;
  left: 4px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.contacts__modal-inner::-webkit-scrollbar {
  display: none;
}
.contacts__modal-inner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modal-open .contacts__modal.active {
  opacity: 1;
  z-index: 100;
  pointer-events: all;
}
.span__hidden {
  opacity: 0;
  pointer-events: none;
  display: block;
}
@media screen and (max-width: 1024px) {
  .span__hidden {
    display: none;
  }
}
.contacts__span {
  position: relative;
  display: block;
  padding: 10px 5px;
}

.contacts__modal-cross-span {
  position: fixed;
  right: 25px;
  top: 19px;
  display: block;
  padding: 7px;
  /* height:20px; */
  /* width: 10px; */
  line-height: 0;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
}
.contacts__modal-cross-span svg {
  fill: #ffffff;
}
.contacts__span a {
  position: absolute;
  /* display: block; */
  left: 50%;
  top: 50%;
  /* padding: 10px 20px; */
  transform: translate(-50%, -50%);
}
.contacts__modal-inner {
  /* position: relative; */
}
.contacts__modal-inner:before {
  /* content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    bottom:0px;
    background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%); */
}
@media screen and (max-width: 1024px) {
  .contacts__modal h2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 1024px) {
  .contacts__button-wrap {
    max-width: 100%;
    box-sizing: border-box;
    column-gap: 10px;
    padding: 0 20px;
    right: 0;
  }
  .contacts__modal-cross-span {
    /* position:static */
  }
  .contacts__modal-inner {
    /* max-width: 90%; */
    /* padding:50px 20px */
    /* padding:0px; */
  }
  .contacts__modal {
    max-width: 90%;
  }
  .contacts__span {
    padding: 0px;
    position: static !important;
    left: 0% !important;
    top: 0% !important;
    height: auto !important;
  }
  .contacts__button {
    width: auto !important;
    height: auto !important;
  }
  .contacts__span a {
    position: static !important;
    transform: translate(0%, 0%);
    padding: 5px 10px;
  }
  .contacts__button {
    padding: 0px;
  }
}
@media screen and (max-width: 768px) {
  .contacts__modal-inner {
    padding: 40px 45px;
  }
  .contacts__modal-inner:before,
  .contacts__modal-inner:after {
    height: 40px;
  }
  .contacts__modal-cross-span {
    top: 5px;
    right: 10px;
  }
}
@media screen and (max-width: 480px) {
  .contacts__modal h2 {
    font-size: 24px;
  }
}
