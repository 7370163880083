*{
    margin: 0;
    padding: 0;
    /* mix-blend-mode: difference */
}
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@800&display=swap');
@font-face {
    font-family: 'Panchang-Extrabold';
    src: url('./fonts/Panchang-Extrabold.ttf');
}
@font-face {
    font-family: 'Panchang-Medium';
    src: url('./fonts/Panchang-Medium.ttf');
}
.modal-open .content__wrapper {
    opacity: 0
}
.content__wrapper{
    width: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
    z-index: 13;
    opacity:1;
    transition: opacity 0.2s linear;
    /* transition-delay: 0.2s; */
}

.content{
    width: 100%;
}
.main-content__title{
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.loaded .main-content__title{
    opacity: 1;
}
.content .box h2, .main-content__title{
    font-family: 'Panchang-Extrabold', sans-serif;
    color:white;
    font-size: 64px;
    text-transform:uppercase;
    /* pointer-events: none; */
}
.box p{
    /* display: inline-block; */
    margin-top: 15px;
    font-family:Arial, Helvetica, sans-serif;
    font-family: 'Panchang-Medium', sans-serif;
    font-size: 18px;
    color:white;
    text-transform: uppercase;
    /* pointer-events: none; */
    opacity: 0;
    transition: opacity 0.5s ease-out;
    /* display:none */
}
.box__content{
    display:flex;
    align-items: center;
    flex-direction:column;
}
.active.box p{
    opacity: 1
}
.content .box a{
    font-family:Arial, Helvetica, sans-serif;
    font-size: 16px;
    color:white;
    text-transform: uppercase;
    cursor: pointer !important;
}
.main-content__title-top {
    color:white
}
.main-content__title-top .box{
    font-family: 'Panchang-Extrabold', sans-serif;
    text-transform: uppercase;
    text-align: center;
    /* font-size: 64px */
}
.main-content__title-top .box h2{
    font-family: 'Panchang-Extrabold', sans-serif;
    font-size: 64px;
    text-transform: uppercase;
    line-height:1!important;
}
.start .main-content__title-top .box {
    opacity: 0
}
.main-content__title{
    transition: opacity 0.2s ease-out;
}
.start.loaded .main-content__title{
    opacity: 0
}
.content .box{
    text-align: center;
    position :absolute;
    top: 50%;
    left: 50%;
    width: max-content;
    max-width: 75vw;
    transform: translate(-50%,-50%);
    opacity: 0;
    transition: opacity 0.1s linear;
    z-index: -1;
    
}
.original__title{
    opacity: 0;
    line-height: 1;
    transition: opacity 1.2s ease-out;
}
.active .original__title{
    /* opacity: 1; */
    /* transition-delay: 0.3s ; */
}
.box__title-wrap{
    position: relative;
    display: inline-block;
    margin-left: 15px;
}
.box__title-wrap:first-child{
    margin-left: 0px;
}
.box__title-inner h2{
    /* position: absolute; */
    /* width: 50%; */
    /* height:50%; */
    /* overflow: hidden; */
    white-space: nowrap;
    /* width: 100%; */
    /* height: 100%; */
}
.box__title-inner{
    display:inline-flex;
    position: absolute;
    font-size:16px;
    line-height: 1;
    width: 50%;
    height: 50%;
    overflow: hidden;
}
/* disable */


.box__title-inner{
    opacity: 0;
    transform: translate(0px,0px);
    transition: transform 1.2s ease-out, opacity 1.3s ease-out;
    /* transition-delay:0.3s ; */
}

.box__title-inner.left-top{
    left: 0;
    top: 0;
    transform: translate(-15px,0px)
}

.box__title-inner.left-bottom{
    left: 0;
    bottom: 0;
    transform: translate(0px,15px)
}
.box__title-inner.right-top{
    right: 1px;
    top: 0;
    transform: translate(0px,-15px)
}

.box__title-inner.right-bottom {
    right: 1px;
    bottom: 0;
    transform: translate(15px,0px)
}
.box__title-inner h2{
    position: absolute;
}
/* active */
.active .box__title-inner{
    opacity: 1;
}
.active .box__title-inner.left-top{
    left: 0;
    top: 0;
    transform: translate(0px,0px)
}

.active .box__title-inner.left-bottom{
    left: 0;
    bottom: 0;
    transform: translate(0px,0px)
}
.active .box__title-inner.right-top{
    right: 1px;
    top: 0;
    transform: translate(0px,0px)
}

.active .box__title-inner.right-bottom {
    right: 1px;
    bottom: 0;
    transform: translate(0px,0px)
}


/*  */
.box__title-inner.right-top h2{
    right: 0;
    top: 0;
}
.box__title-inner.left-top h2{
    left: 0;
    top: 0;
}
.box__title-inner.right-bottom h2{
    right: 0;
    bottom: 0;
}
.box__title-inner.left-bottom h2{
    left: 0;
    bottom: 0;
}




.start .content .box.active{
    opacity: 1;
    /* transition-delay: 0.2s; */
    z-index: 10;
}
@media screen and (max-width:1500px){
    .main-content__title, .main-content__title-top .box h2{
        font-size:48px
    }
}
@media screen and (max-width:1200px){
    .content .box h2, .main-content__title, .main-content__title-top .box h2{
        font-size:40px
    }
}
@media screen and (max-width:1024px) {
    .content .box h2, .main-content__title,.main-content__title-top .box h2{
        font-size:32px
    }
    
}
@media screen and (max-width:768px){
    .content .box{
        max-width:100%;
        
    }
    .main-content__title, .main-content__title-top .box h2{
        font-size:24px
    }
}
@media screen and (max-width:600px){
    .content .box{
        max-width: calc(100% - 40px);
    }
}
@media screen and (max-width:768px){
    .content .box{
        max-width:100%;
        
    }
    .content .box h2{
        /* font-size:20px */
    }
}