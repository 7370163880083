@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
*{
  margin: 0;
  padding: 0;
}
html,body,#root{
  height: 100%;
}
body{
  background:black;
}
body.modal-open{
  cursor:default !important;
}
a{
  color:white
}
.loading-wrap{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background:black;
  z-index: 999;
}
.loaded .loading-wrap{
  background:transparent !important;
  pointer-events: none !important;
  z-index: -999 !important;
}
.modal-open .canvas__wrapper{
  filter: blur(8px);
}

@media screen and (max-width: 1024px){
  .mouseIMG{
    opacity: 0!important;
  }
  
}